import React from "react";
// import { NavLink } from "react-router-dom";
import { NavHashLink as NavLink } from "react-router-hash-link";

import {
    SidebarWrapper,
    SidebarHeader,
    SidebarItem,
    SidebarItemIcon,
    SidebarItemText,
    SublinkGroup,
    Text,
} from "reactised-fictoan";

import logoAxis from "../../assets/images/logo-axis.svg";
import iconAxis from "../../assets/images/icon-axis.svg";
import logoSetu from "../../assets/images/logo-dp-on-tp.svg";
import iconSetu from "../../assets/images/icon-ft-on-dp.svg";

// import iconHome from "../../assets/icons/home.svg";
import { ReactComponent as Home } from "../../assets/icons/home.svg";
import { ReactComponent as Apis } from "../../assets/icons/apis.svg";
import { ReactComponent as Apps } from "../../assets/icons/apps.svg";

class Sidebar extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
            visible: props.visible,
            width: window.innerWidth,
            height: window.innerHeight,
        };
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    isCollapsed = () => {
        const isMobileWidth = this.state.width <= 600;
        if (isMobileWidth) {
            return false;
        } else if (this.props.location.pathname.includes("api")) {
            return true;
        }

        return this.state.collapsed;
    };

    hideIfMobile = () => {
        this.setState({
            visible: this.state.width > 600,
        });
    };

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.visible !== this.props.visible) {
            this.setState({
                visible: this.props.visible,
            });
        }
    }

    headerOnClick = () => {
        this.setState((prevState) => ({
            collapsed: !prevState.collapsed,
        }));
    };

    render() {
        const commonProps = {
            onClick: this.hideIfMobile,
        };

        return (
            <SidebarWrapper
                className={`${this.isCollapsed() ? "collapsed" : ""} ${
                    this.state.visible ? "visible" : ""
                }`}
            >
                <SidebarHeader onClick={this.headerOnClick}>
                    <img
                        className="header-logo"
                        src={logoAxis}
                        alt="Axis logo"
                    />
                    <img
                        className="header-icon"
                        src={iconAxis}
                        alt="Axis logo"
                    />
                </SidebarHeader>

                <NavLink exact to="/" {...commonProps}>
                    <SidebarItem>
                        <SidebarItemIcon>
                            <Home />
                        </SidebarItemIcon>
                        <SidebarItemText linkText="Home" />
                    </SidebarItem>
                </NavLink>
                {/* <details>
          <summary>
            <SidebarItem>
              <SidebarItemIcon>
                <Apps />
              </SidebarItemIcon>
              <SidebarItemText linkText="FASTag" />
            </SidebarItem>
          </summary>
          <SublinkGroup>
            <NavLink exact smooth to="/fastag/product" {...commonProps}>
              <SidebarItem>
                <SidebarItemIcon>
                  <Docs />
                </SidebarItemIcon>
                <SidebarItemText
                  className="sidebar-sublink"
                  linkText="Product"
                />
              </SidebarItem>
            </NavLink>
            <NavLink to="/fastag/guide" {...commonProps}>
              <SidebarItem>
                <SidebarItemIcon>
                  <Dot />
                </SidebarItemIcon>
                <SidebarItemText
                  className="sidebar-sublink"
                  linkText="Integration Guide"
                />
              </SidebarItem>
            </NavLink>
            <NavLink exact to="/fastag/api" {...commonProps}>
              <SidebarItem>
                <SidebarItemIcon>
                  <Apis />
                </SidebarItemIcon>
                <SidebarItemText linkText="API Reference" />
              </SidebarItem>
            </NavLink>
            <NavLink to="/fastag/push-notifications" {...commonProps}>
              <SidebarItem>
                <SidebarItemIcon>
                  <Dot />
                </SidebarItemIcon>
                <SidebarItemText
                  className="sidebar-sublink"
                  linkText="Push Notifications"
                />
              </SidebarItem>
            </NavLink>
          </SublinkGroup>
        </details> */}
                <details>
                    <summary>
                        <SidebarItem>
                            <SidebarItemIcon>
                                <Apps />
                            </SidebarItemIcon>
                            <SidebarItemText linkText="BBPS BOU" />
                        </SidebarItem>
                    </summary>
                    <SublinkGroup>
                        <NavLink to="/bbps/biller/guide" {...commonProps}>
                            <SidebarItem>
                                <SidebarItemIcon>
                                    <Apis />
                                </SidebarItemIcon>
                                <SidebarItemText
                                    className="sidebar-sublink"
                                    linkText="Biller APIs"
                                />
                            </SidebarItem>
                        </NavLink>
                        <NavLink to="/bbps/1.1/biller/guide" {...commonProps}>
                            <SidebarItem>
                                <SidebarItemIcon>
                                    <Apis />
                                </SidebarItemIcon>
                                <SidebarItemText
                                    className="sidebar-sublink"
                                    linkText="Biller APIs (1.1 spec)"
                                />
                            </SidebarItem>
                        </NavLink>
                        <NavLink to="/bbps/settlements" {...commonProps}>
                            <SidebarItem>
                                <SidebarItemIcon>
                                    <Apis />
                                </SidebarItemIcon>
                                <SidebarItemText
                                    className="sidebar-sublink"
                                    linkText="Split Settlement"
                                />
                            </SidebarItem>
                        </NavLink>
                        <NavLink to="/bbps/plan-mdm" {...commonProps}>
                            <SidebarItem>
                                <SidebarItemIcon>
                                    <Apis />
                                </SidebarItemIcon>
                                <SidebarItemText
                                    className="sidebar-sublink"
                                    linkText="Plan MDM"
                                />
                            </SidebarItem>
                        </NavLink>
                        <NavLink to="/bbps/b2b" {...commonProps}>
                            <SidebarItem>
                                <SidebarItemIcon>
                                    <Apis />
                                </SidebarItemIcon>
                                <SidebarItemText
                                    className="sidebar-sublink"
                                    linkText="BBPS B2B"
                                />
                            </SidebarItem>
                        </NavLink>
                    </SublinkGroup>
                </details>
                <details>
                    <summary>
                        <SidebarItem>
                            <SidebarItemIcon>
                                <Apps />
                            </SidebarItemIcon>
                            <SidebarItemText linkText="BBPS COU" />
                        </SidebarItem>
                    </summary>
                    <SublinkGroup>
                        <NavLink to="/cou/api" {...commonProps}>
                            <SidebarItem>
                                <SidebarItemIcon>
                                    <Apis />
                                </SidebarItemIcon>
                                <SidebarItemText
                                    className="sidebar-sublink"
                                    linkText="API Reference"
                                />
                            </SidebarItem>
                        </NavLink>
                        <NavLink to="/cou/instacollect/api" {...commonProps}>
                            <SidebarItem>
                                <SidebarItemIcon>
                                    <Apis />
                                </SidebarItemIcon>
                                <SidebarItemText
                                    className="sidebar-sublink"
                                    linkText="Axis InstaCollect"
                                />
                            </SidebarItem>
                        </NavLink>
                    </SublinkGroup>
                </details>

                {/* <details>
                    <summary>
                        <SidebarItem>
                            <SidebarItemIcon>
                                <Docs />
                            </SidebarItemIcon>
                            <SidebarItemText linkText="Guides" />
                        </SidebarItem>
                    </summary>

                    <SublinkGroup>
                        <NavLink to="/jwt" {...commonProps}>
                            <SidebarItem>
                                <SidebarItemIcon>
                                    <Dot />
                                </SidebarItemIcon>
                                <SidebarItemText className="sidebar-sublink" linkText="Authentication" />
                            </SidebarItem>
                        </NavLink>

                        <NavLink to="/encryption" {...commonProps}>
                            <SidebarItem>
                                <SidebarItemIcon>
                                    <Dot />
                                </SidebarItemIcon>
                                <SidebarItemText className="sidebar-sublink" linkText="Encryption" />
                            </SidebarItem>
                        </NavLink>
                        <NavLink to="/fastag/bc-guide" {...commonProps}>
                            <SidebarItem>
                                <SidebarItemIcon>
                                    <Dot />
                                </SidebarItemIcon>
                                <SidebarItemText className="sidebar-sublink" linkText="BC Integration" />
                            </SidebarItem>
                        </NavLink>
                        <NavLink to="/fastag/recharge-guide" {...commonProps}>
                            <SidebarItem>
                                <SidebarItemIcon>
                                    <Dot />
                                </SidebarItemIcon>
                                <SidebarItemText className="sidebar-sublink" linkText="Recharge API" />
                            </SidebarItem>
                        </NavLink>
                    </SublinkGroup>
                </details> */}

                {/* <HRule thin bgColor="slate-20" marginTop="fixed" marginBottom="fixed" />

                <SidebarItem>
                    <SidebarItemIcon>
                        <OrangeDot/>
                    </SidebarItemIcon>
                    <SidebarItemText className="section-header" linkText="FASTag" />
                </SidebarItem>

                <details>
                    <summary>
                        <SidebarItem>
                            <SidebarItemIcon>
                                <Apps />
                            </SidebarItemIcon>
                            <SidebarItemText linkText="Product" />
                        </SidebarItem>
                    </summary>

                    <SublinkGroup>
                        <NavLink exact smooth to="/fastag#overview" {...commonProps}>
                            <SidebarItem>
                                <SidebarItemIcon>
                                    <Dot />
                                </SidebarItemIcon>
                                <SidebarItemText className="sidebar-sublink" linkText="Overview" />
                            </SidebarItem>
                        </NavLink>

                        <NavLink exact smooth to="/product/categories" {...commonProps}>
                            <SidebarItem>
                                <SidebarItemIcon>
                                    <Dot />
                                </SidebarItemIcon>
                                <SidebarItemText className="sidebar-sublink" linkText="Partner categories" />
                            </SidebarItem>
                        </NavLink>

                        <NavLink exact smooth to="/product/integration" {...commonProps}>
                            <SidebarItem>
                                <SidebarItemIcon>
                                    <Dot />
                                </SidebarItemIcon>
                                <SidebarItemText className="sidebar-sublink" linkText="Getting started" />
                            </SidebarItem>
                        </NavLink>

                        <NavLink exact smooth to="/product/user-flow" {...commonProps}>
                            <SidebarItem>
                                <SidebarItemIcon>
                                    <Dot />
                                </SidebarItemIcon>
                                <SidebarItemText className="sidebar-sublink" linkText="Sample userflow" />
                            </SidebarItem>
                        </NavLink>
                    </SublinkGroup>
                </details>

                <NavLink exact to="/fastag/error-codes" {...commonProps}>
                    <SidebarItem>
                        <SidebarItemIcon>
                            <Transactions />
                        </SidebarItemIcon>
                        <SidebarItemText linkText="Response codes" />
                    </SidebarItem>
                </NavLink> */}

                <SidebarItem className="bottom-section">
                    <SidebarItemIcon>
                        <img src={iconSetu} alt="Setu" />
                    </SidebarItemIcon>
                    <div className="setu-logo vertically-center-items">
                        <Text
                            size="small"
                            marginBottom="none"
                            marginRight="fixed"
                        >
                            Powered by
                        </Text>
                        <img src={logoSetu} alt="Setu" />
                    </div>
                </SidebarItem>
            </SidebarWrapper>
        );
    }
}

export default Sidebar;
