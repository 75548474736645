// eslint-disable-next-line import/no-webpack-loader-syntax
import bcGuide from "!raw-loader!./md/bc-guide.md";
// eslint-disable-next-line import/no-webpack-loader-syntax
import rechargeGuide from "!raw-loader!./md/recharge-guide.md";
// eslint-disable-next-line import/no-webpack-loader-syntax
import bcOverview from "!raw-loader!./md/bc-overview.md";
// eslint-disable-next-line import/no-webpack-loader-syntax
import corpOverview from "!raw-loader!./md/corp-overview.md";
// eslint-disable-next-line import/no-webpack-loader-syntax
import fastagIntro from "!raw-loader!./md/fastag-intro.md";
// eslint-disable-next-line import/no-webpack-loader-syntax
import integrationIntro from "!raw-loader!./md/integration.md";
// eslint-disable-next-line import/no-webpack-loader-syntax
import javaSdk from "!raw-loader!./md/java.md";
// eslint-disable-next-line import/no-webpack-loader-syntax
import notifications from "!raw-loader!./md/notifications.md";
// eslint-disable-next-line import/no-webpack-loader-syntax
import notificationsAxis from "!raw-loader!./md/notifications_axis_version.md";
// eslint-disable-next-line import/no-webpack-loader-syntax
import bbpsBiller from "!raw-loader!./md/bbps.md";
// eslint-disable-next-line import/no-webpack-loader-syntax
import bbps1_1Biller from "!raw-loader!./md/bbps-1.1.md";
// eslint-disable-next-line import/no-webpack-loader-syntax
import bbpsSettlements from "!raw-loader!./md/settlements.md";
// eslint-disable-next-line import/no-webpack-loader-syntax
import bbpsPlanMdm from "!raw-loader!./md/plan-mdm.md";
// eslint-disable-next-line import/no-webpack-loader-syntax
import bbpsB2B from "!raw-loader!./md/bbps-b2b.md";

let Content = {
    bcGuide: bcGuide,
    bcOverview: bcOverview,
    corpOverview: corpOverview,
    fastagIntro: fastagIntro,
    rechargeGuide: rechargeGuide,
    javaSdk: javaSdk,
    bbpsBiller: bbpsBiller,
    bbps1_1Biller: bbps1_1Biller,
    bbpsSettlements: bbpsSettlements,
    bbpsPlanMdm: bbpsPlanMdm,
    bbpsB2B: bbpsB2B,
    notifications: notifications,
    notificationsAxis: notificationsAxis,
    integrationIntro: integrationIntro
}

export default Content;

